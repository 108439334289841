import React, { Component } from 'react';
import LoadingOverlay from './LoadingOverlay';
import BuildModel from '../scene/BuildModel';
import ArrangeScene from '../scene/ArrangeScene';
import {
  MirrorSetup,
} from "../scene/BuildMirror";


const activeEnv = process.env.ACTIVE_ENV || process.env.NODE_ENV || "development";

// Interaction vars
let MOUSE_DOWN = false;

export default class SceneComponent extends Component {
  constructor(props) {
    super(props);
    this.scrollTop = 0;
    this.state = {
      loaded: false
    };
    // this.clock = new THREE.Clock()
  }

  
  componentDidMount = () => {
    const loadjs = require('loadjs');
    this.mixers = [];
    this.loadThree( loadjs )
      .then( this.loadThreeUtils.bind(null, loadjs) )
      .then(async () => {
        this.setupRenderer();
        this.setupCamera();
        this.setupControls();
        this.setupScene();
        this.setupLights();
        await this.buildScene();
        this.render();
        this.setState({
          loaded: true
        });
        this.animate();
      });
  }


  loadThree = ( loadjs ) => {
    const threePath = '/three/three.109.min.js';
    return new Promise(function(resolve, reject) {
      loadjs([threePath], 'three');
      loadjs.ready('three', {
        success: resolve,
        error: reject
      });
    });
  }

  loadThreeUtils = ( loadjs ) => {
    // const loadjs = require('loadjs');
    const utils = [
			'/three/GLTFLoader.js',
			'/three/SkeletonUtils.js',
			'/three/OrbitControls.js',
		];
    return new Promise(function(resolve, reject) {
      loadjs(utils, 'threeUtils');
      loadjs.ready('threeUtils', {
        success: resolve,
        error: reject
      });
    });
  }

  setupCamera = () => {
    const { innerWidth: width, innerHeight: height } = window;
    this.camera = new THREE.PerspectiveCamera(60, width / height, 10, 1000);
    this.camera.position.set(0, 25, 160);
  }

  setupRenderer = () => {
    const { innerWidth: width, innerHeight: height } = window;
    this.WIDTH = width;
    this.HEIGHT = height;
    
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      preserveDrawingBuffer: false,
    });

    const pixelRatio = window.devicePixelRatio || 1;
    this.renderer.setPixelRatio(pixelRatio);
    this.renderer.setSize(this.WIDTH, this.HEIGHT);
    this.renderer.autoClear = false;
    // Setup resize events
    window.addEventListener('resize', () => this._resize());
    window.addEventListener('orientationchange', () => this._resize());
  }

  setupScene = () => {
    this.clock = new THREE.Clock(true);
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);
    this.scene.fog = new THREE.Fog(0x000000, 100, 300);
  }

  setupControls = () => {
    this.controls = new THREE.OrbitControls(this.camera, this.containerElement);
    this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    this.controls.dampingFactor = 0.05;
    this.controls.screenSpacePanning = false;
    this.controls.minDistance = 20;
    this.controls.maxDistance = 500;
    this.controls.maxPolarAngle = Math.PI / 2;
  }

  buildVideoCamera = () => {
    const modelSrc = '/models/camera.glb';
    return new Promise((resolve) => {
      const loader = new THREE.GLTFLoader();
      loader.load(
        modelSrc,
        gltf => {
          const { scene } = gltf;
          const camera = scene.children[0];
          const scale = 60;
          camera.scale.set(scale, scale, scale);
          resolve(scene.children[0]);
        }
      );

    });
  }

  buildScene = async () => {
    // Create gingerbread man
    const { meshes: modelMeshes, mixers } = await BuildModel();
    this.mixers = mixers;
    // Setup the mirror
    this.mirror = MirrorSetup(this.scene, this.WIDTH, this.HEIGHT);
    const videoCamera = await this.buildVideoCamera();

    // Position everything
    ArrangeScene({
      scene: this.scene,
      mirror: this.mirror,
      modelMeshes,
      videoCamera,
    });
  }

  setupLights = () => {
    const point = new THREE.PointLight(0xffffff, 0.8);
    point.position.y = 100;
    point.position.z = 80;
    this.scene.add(point);
  }

  animate = () => {
    // Start loop again
    this.controls.update();
    requestAnimationFrame(this.animate);
    this.renderScene();
  }

  renderScene = () => {
    const delta = this.clock.getDelta();
    for ( var i = 0; i < this.mixers.length; ++ i ) {
      this.mixers[ i ].update( delta );
    }

    // render env to fbo
    this.renderer.setRenderTarget(this.mirror.envFbo);
    this.renderer.render(this.mirror.scene, this.mirror.camera);
    this.renderer.setRenderTarget(null);

    // render diamond
    this.renderer.render(this.scene, this.camera);
  }

  // COMPONENT RENDER
  render() {
    return (
      <div className="three-container" ref={element => this.containerElement = element}>
        <LoadingOverlay loaded={this.state.loaded} />
        <canvas id="three-canvas" ref={element => this.canvas = element} />
      </div>
    );
  }

  // EVENTS
  _resize() {
    let { innerWidth: width, innerHeight: height } = window;
    this.WIDTH = width;
    this.HEIGHT = height;
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderScene();
  }
}
