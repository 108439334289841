const ArrangeScene = ({ scene, mirror, modelMeshes, videoCamera }) => {
	const [man, secondMan] = modelMeshes;
	// Position mirror
	mirror.group.rotation.y = 0;
	mirror.group.position.x = 0;
	// Position man
	man.position.x = 0;
	man.position.y = -35;
	man.position.z = 80;
	man.rotation.y = Math.PI;
	secondMan.position.x = man.position.x + 10;
	secondMan.position.y = man.position.y + 2;
	secondMan.position.z = man.position.z;
	secondMan.rotation.y = Math.PI;
	secondMan.scale.multiplyScalar(1.3);
	// FLOOR
	const floor = buildFloor();
	scene.add(floor);
	// Add things to scenes
	console.log('secondMan', secondMan);
	scene.add(man);
	scene.add(mirror.group.clone());

	mirror.camera.position.x = 15;
	mirror.camera.position.y = 17;
	mirror.camera.position.z = 0;
	mirror.camera.rotation.x = 0.2;
	mirror.camera.rotation.y = Math.PI;
	
	videoCamera.position.x = 15;
	videoCamera.position.y = 20;
	videoCamera.rotation.x = 0.2;
	scene.add(videoCamera);
	mirror.scene.add(secondMan);
};

const buildFloor = () => {
	const geometry = new THREE.PlaneGeometry(800, 800, 1, 1);
	const material = new THREE.MeshPhongMaterial({
		color: 0xffb54a,
		shininess: 12,
		specular: 0xffb54a,
		side: THREE.DoubleSide,
	});
	const mesh = new THREE.Mesh(geometry, material);
	mesh.position.y = -35;
	mesh.rotation.x = Math.PI / 2;
	return mesh;
};


export default ArrangeScene;