import React from 'react'
import { graphql } from 'gatsby'
import { cloneDeep } from 'lodash';

import Layout from '../globals/layout'
import SEO from '../globals/seo'

import SceneComponent from '../components/SceneComponent'

// import Mousetrap from 'mousetrap'

let device;

class IndexPage extends React.Component {
  
  constructor(props) {
    super(props);
    // Set initial state
    this.state = {
      device: null,
    };
  }

  componentDidMount() {
    // Load device package
    device = require('current-device').default;
    this.setState({
      device: device
    });
  }
  
  render() {
    return(
      <Layout>
        <SEO />
        <SceneComponent device={this.state.device} />
      </Layout>
    );
  }
}

export default IndexPage;