const MirrorSetup = () => {
	const pixelRatio = window.devicePixelRatio || 1;

	const scene = new THREE.Scene();
	scene.background = new THREE.Color(0xffb54a);

	const envFbo = new THREE.WebGLRenderTarget(
		512 * pixelRatio,
		512 * pixelRatio
	);

	const mirrorGroup = new THREE.Group();

	const camera = new THREE.PerspectiveCamera(45, 512 / 512, 10, 1000);
	camera.position.z = 100;
	scene.add(camera);

	const mirrorFrontGeometry = new THREE.PlaneGeometry(60, 30, 1, 1);
	const mirrorMaterial = new THREE.MeshLambertMaterial({
		map: envFbo.texture,
		color: 0xcccccc,
	});

	const mirrorFrameMaterial = new THREE.MeshBasicMaterial({
		color: 0x111111,
	});
	const mirrorFrame = new THREE.Mesh(mirrorFrontGeometry, mirrorFrameMaterial);
	mirrorFrame.scale.set(1.05, 1.1, 1);
	mirrorGroup.add(mirrorFrame);
			
	const mirrorFront = new THREE.Mesh(mirrorFrontGeometry, mirrorMaterial);
	mirrorFront.position.z = 0.1;
	mirrorGroup.add(mirrorFront);

	const mirrorBackGeometry = new THREE.PlaneGeometry(130, 70, 1, 1);
	const mirrorBackMaterial  = new THREE.MeshLambertMaterial({
		color: 0x999999,
		side: THREE.DoubleSide,
	});
	const mirrorBack = new THREE.Mesh(mirrorBackGeometry, mirrorBackMaterial);
	mirrorBack.position.z = -0.1;
	mirrorGroup.add(mirrorBack);

	

	return {
		scene,
		envFbo,
		camera,
		group: mirrorGroup,
	};

};

export { MirrorSetup };