// const modelSrc = '/models/pige-animated-3.glb';

const BuildModel = async () => {
	const modelSrc = '/models/man.glb';
	return new Promise((resolve, reject) => {
		const loader = new THREE.GLTFLoader();
		loader.load(
			modelSrc,
			gltf => {
				// return
				// called when the resource is loaded
				const material = new THREE.MeshNormalMaterial({
					morphTargets: true,
					skinning: true,
				});
				material.needsUpdate = true;
				gltf.scene.children[0].traverse(( node ) => {
					if (node.isMesh & node.type == 'SkinnedMesh') {
						node.material = material;
						node.geometry.computeFaceNormals();
					}
				});
				
				const scale = 15;
				const animations = gltf.animations;
				const mesh1 = gltf.scene;
				mesh1.scale.set(scale, scale, scale);
				
				const mesh2 = THREE.SkeletonUtils.clone(mesh1);

				const mixer1 = setupAnimations(mesh1, animations);
				const mixer2 = setupAnimations(mesh2, animations);
				
				const meshes = [mesh1, mesh2];
				const mixers = [mixer1, mixer2];
				resolve({meshes, mixers});
			},
			xhr => {
				// called while loading is progressing
				console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
			},
			error => {
				// called when loading has errors
				console.error('An error happened', error);
				reject(error);
			},
		);
	});
};

const setupAnimations = (scene, animations) => {
	const [wave] = animations;
	const mixer = new THREE.AnimationMixer(scene);
	const action = mixer.clipAction( wave );
	action.setDuration( 2.5 );
	action.loop = THREE.LoopPingPong;
	action.play();
	return mixer;
};

export default BuildModel;
